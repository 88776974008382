import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from "vue-axios";
import axios from "axios";
axios.defaults.baseURL = "https://swifud.alexanderwodarz.de/api"

import Notifications from '@kyvg/vue3-notification'
import stripe from "@/plugins/stripe";
import '@/style.css';

const app = createApp(App)
app.use(stripe, { key: 'pk_test_51Hgz4CHzPCIbQ22vojULfTD6l1ysNCsDevLMdiWaolHpxWeQ8P4PwWP7mV4IgJfhEhDCPmD8FoZhEeoUM7srHhYy00zDMG6wJe' })
app.use(VueAxios, axios)
app.use(router)
app.use(Notifications)
router.isReady().then(() => {

    app.mount('#app')
})
