import {loadStripe} from '@stripe/stripe-js'

export default {
    async install(Vue, options) {
        const stripe = await loadStripe(options.key)
        const elem = {}
        let payment = null
        const w = {
            confirm: async (secret) => {
                const result = await stripe.confirmPayment({
                    elements: elem[secret],
                    confirmParams: {
                        return_url: window.location.href
                    }
                })
                return result
            },
            createPaymentField: (secret) => {
                const elements = stripe.elements({clientSecret: secret})
                elem[secret] = elements
                payment = elements.create('payment', {
                    wallets: {
                        applePay: "auto",
                        googlePay: "auto"
                    },
                    layout: {
                        type: 'accordion'
                    }
                })
                payment.mount('#card-element')
                const paymentRequest = stripe.paymentRequest({
                    country: 'DE',
                    currency: 'EUR',
                    total: {
                        label: 'Demo total',
                        amount: 100,
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                });
                const prButton = elements.create('paymentRequestButton', {
                    paymentRequest,
                });

                (async () => {
                    const result = await paymentRequest.canMakePayment();
                    if (result) {
                        prButton.mount('#payment-request-button');
                    } else {
                        document.getElementById('payment-request-button').style.display = 'none';
                    }
                })();
            },
            clear(){
                payment.clear()
            }
        }
        Vue.config.globalProperties.$stripe = w
    },
}
