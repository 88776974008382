import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    name: 'invoice',
    path: '/invoice/:invoiceNumber',
    component: () => import('@/views/InvoiceView.vue')
  },
  {
    name: 'pay',
    path: '/invoice/:invoiceNumber/pay',
    component: () => import('@/views/PayView.vue')
  },
  {
    name: 'base',
    path: '/invoice/:invoiceNumber/base',
    component: () => import('@/views/BaseView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
